<template>
  <div class="wrapper">
    <div class="page" >
      <div id="main-loading-cover" >
        <div class="page-inner" id="main_page">
          <div class="va-card mb-4">
            <div class="dash_card_body pad-lr-0 ov-h">
              <div class="header_section d-block padd-30 pt-0 pb-0">
                <div class="w-100 t-wrap">
                  <h4 class="menu_header">Gift Card</h4>
                </div>
                <div class="back-btn">
                  <router-link :to="{ name: 'giftcardstype'}"><i class="fa fa-arrow-left" aria-hidden="true"></i>
                  </router-link>
                  <span>{{title}}</span>
                </div>
              </div>

              <div class="create_items_section">
                <form enctype="multipart/form-data" @submit.prevent="updateVoucher()">
                  <div v-if="errors ">
                    <div class="alert alert-danger">
                      <ul>
                        <li v-for="(err_msg,errindex) in errorMsg" :key="errindex">{{err_msg}}</li>
                      </ul>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6 bd-right pad-lr-0">
                      <div class="pl-5 pr-4 bd-b">
                        <div class="form-group setting_form_grp">
                          <label for="name">Title</label>
                          <div>
                            <input type="text" class="form-control" v-model="name" @blur="v$.name.$touch" placeholder="Enter Name">
                          </div>
                          <div class="input_error" v-if="v$.name.$error">Title is required.</div> 
                        </div>
                        <div class="form-group setting_form_grp">
                          <label for="name">Status:</label>
                          <select class="form-control" v-model="status">
                            <option value="1">Published</option>
                            <option value="2">Unpublished</option>
                            <option value="3">Archived</option>
                            <option value="4">Trashed</option>
                          </select>
                        </div>
                        <div class="form-group setting_form_grp">
                          <label data-baseweb="form-control-label">Description</label>
                            
                          <div class="desc-wrap">
                            <editor v-model="description" :init="{
                              height: 200,
                              branding: false
                            }" />
                          <!-- dds -->
                          </div>
                        </div>
                      </div><!-- END Title and Desc-->

                      <div id="foodie-loading-cover" class="pl-5 pr-4 bd-b">
                      
                        <div class="form-group setting_form_grp">
                          <h2 class="section-title">Media</h2>
                              
                          <div class="image_div">
                            <div class="img_preview" style="">
                              <div tabindex="0" style="display: flex; justify-content: center; width: 100%; height: 100%; border: 2px dashed rgb(229, 229, 229); background: rgb(247, 247, 247) none repeat scroll 0% 0%; flex-direction: column; align-items: center; padding: 20px 0px; box-sizing: border-box;">
                                <img class="image_responsive" v-if="images" :src="images" />
                              </div>
                            </div>
                            <div class="image_content">
                              <div class="image_button">
                                <div v-if="images"  :class="['add_image_btn_grp', {'item_btn_add_grp': images}, 'mt-1']">
                                  <div class="btn_plus_icon">
                                    <i class="fa fa-minus" style='font-size:24px' @click="removeImage()"></i>
                                  </div>
                                  <div class="btn_url">
                                    <span>Remove Image</span>
                                  </div>
                                </div> 

                                <div :class="['add_image_btn_grp', {'item_btn_add_grp': images}, 'mt-2']">
                                  <div class="btn_plus_icon">
                                    <input id='fileid' type='file'  @change="uploadGiftcardImage($event, 1000, 800)"  hidden/>
                                    <i class="fa fa-plus" style='font-size:24px' @click="openFileUploadDialog()"></i>
                                  </div>
                                  <div class="btn_url">
                                    <span>Add from URL</span>
                                  </div>
                                </div> 
                                
                              </div>

                             
                            </div>
                          </div>
                        </div>
                        <div id="foodie-loader"> </div>
                      </div><!-- END image-->

                    
                      <div class="setting_content pl-5 pr-4 pt-4">

                        <div class="form-group setting_form_grp">
                          <div class="row">
                            <label class="control-label col-sm-5" for="dm_consumer_secret">Use Percentage Based:</label>
                            <div class="col-sm-6">
                              <label class="radio-inline"><input type="radio" v-model="usePercentageBased" :value="1">&nbsp; Yes
                              </label> &nbsp;&nbsp;
                              <label class="radio-inline"><input type="radio" v-model="usePercentageBased" :value="0"> &nbsp;
                                No</label>
                              
                            </div>

                          </div>
                        </div>

                        <div v-if="usePercentageBased == 0">
                          <div class="form-group setting_form_grp">
                            <div class="row">
                              <label class="control-label col-sm-5" for="name">Amount:</label>
                              <div class="col-sm-6">
                                <div>
                                  <input type="text" class="form-control" v-model="amount" @blur="v$.amount.$touch" placeholder="Enter Amount">
                                </div>
                                <div class="col-sm-6 input_error" v-if="v$.amount.$error">Amount field is required.</div>

                              </div>

                            </div>
                          </div><!--Amount-->
                          <div class="form-group setting_form_grp">
                            <div class="row">
                              <label class="control-label col-sm-5" for="dm_consumer_secret">Custom Amount:</label>
                              <div class="col-sm-6">
                                <label class="radio-inline"><input type="radio" v-model="customAmount" :value="1">&nbsp; Yes </label> &nbsp;&nbsp;
                                <label class="radio-inline"><input type="radio" v-model="customAmount" :value="0"> &nbsp; No</label>
                              </div>
                            </div>
                          </div><!--custome Amount-->


                          <div class="form-group setting_form_grp">
                            <div class="row">
                              <label class="control-label col-sm-5" for="dm_consumer_secret">Allow Multiple Amount:</label>
                              <div class="col-sm-6">
                                <label class="radio-inline"><input type="radio" v-model="allowMultipleAmount" :value="1">&nbsp; Yes
                                </label> &nbsp;&nbsp;
                                <label class="radio-inline"><input type="radio" v-model="allowMultipleAmount" :value="0"> &nbsp;
                                  No</label>
                              </div>
                            </div>
                          </div>
                          <!--  -->
                          <div class="form-group setting_form_grp" v-if="allowMultipleAmount">
                            <hr>
                            <div class="row mt-4 mb-4">
                              <div class="col-md-12">
                                <div class="row">
                                  <div class="col-lg-7 col-md-7 col-sm-7">
                                    <input type="text" class="form-control" placeholder="Enter Amount" v-model="vAmount">
                                  </div>
                                  <div class="col-lg-4 col-md-4 col-sm-3">
                                    <button type="button" @click.prevent="addAmount" :class="['w-100 btn',{'custon_orange':!disableAddBtn},{'btn-dgrey':disableAddBtn}]" :disabled="disableAddBtn">Add</button>
                                  </div>
                                </div>
                                <div class="row mt-3">
                                  <div class="col-md-12">
                                    <span class="badge badge_review mr-2" v-for="vamount in amountArr" :key="vamount" >
                                      &nbsp; {{vamount}}&nbsp;&nbsp; <span class="float-right remove_icon" @click="removeAmount(vamount)" aria-hidden="true">&times;</span>
                                    </span>
                                
                                  </div>
                                </div>
                              </div>
                            </div>
                            <hr>
                          </div>
                        </div>

                        <div v-else class="form-group">
                          <hr>
                          <small v-if="usePercentageBased == 1" class="mt-2 mb-3 form-text text-red" style="color:red"> voucher is only applicable for admin generated vouchers and is preferred to be used only once</small>


                          <div class="form-group  setting_form_grp">
                            <div class="row">
                              <label class="control-label col-sm-5" for="dm_consumer_secret">based On Discount Codes :</label>
                              <div class="col-sm-6">
                                <label class="radio-inline"><input type="radio" v-model="allowDiscountCode" :value="1">&nbsp; Yes
                                </label> &nbsp;&nbsp;
                                <label class="radio-inline"><input type="radio" v-model="allowDiscountCode" :value="0"> &nbsp;
                                  No</label>
                            
                              </div>

                            </div>
                          </div>


                          <div class="row mt-4 mb-4" v-if="allowDiscountCode == 1">
                            <div class="col-md-12">
                              <div class="row">
                                <div class="col-lg-9 col-md-9 col-sm-9">
                                  <div style="display: flex; justify-content: space-between; gap: 10px;">
                                    <input type="text" class="form-control" placeholder="Enter Discount Code" v-model="discountCode">
                                    <input type="text" class="form-control" placeholder="Enter Percentage" v-model="discountPercentage">

                                  </div>
                                </div>
                                <div class="col-lg-3 col-md-3 col-sm-3">
                                  <button type="button" @click.prevent="addDiscount" :class="['w-100 btn',{'custon_orange':!disableAddBtn},{'btn-dgrey':disableAddBtn}]" :disabled="disableAddBtn">Add</button>
                                </div>
                              </div>
                      
                            </div>
                            <div class="col-md-12" v-if="discountPercentageArray.length > 0">
                           
            
                              <div class="row mt-3">
                                <div class="col-md-4 badge badge_review mr-2" style="text-align: left;"  v-for="discount in discountPercentageArray" :key="discount.discount_code">
                                  <span class="float-right remove_icon" @click="removediscountPercentage(discount.discount_code)" aria-hidden="true">&times;</span>

                                  <div>Discount Code: <b>{{ discount.discount_code }}</b> </div>
                                  <div>Discount Value: <b> {{ discount.percentage_value }} %</b></div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="form-group setting_form_grp" v-else>
                            <div class="row">
                              <label class="control-label col-sm-5" for="name">Discount %:</label>
                              <div class="col-sm-6">
                                <div>
                                  <input type="text" class="form-control" v-model="percentValue" placeholder="Enter Amount">
                                </div>
                              </div>

                            </div>
                          </div>
                          <hr>
                        </div>




                       

                        <div class="form-group setting_form_grp">
                          <div class="row">
                            <label class="control-label col-sm-5" for="dm_consumer_secret">Show On Web:</label>
                            <div class="col-sm-6">
                              <label class="radio-inline"><input type="radio" v-model="showOnWeb" :value="1">&nbsp; Yes
                              </label> &nbsp;&nbsp;
                              <label class="radio-inline"><input type="radio" v-model="showOnWeb" :value="0"> &nbsp;
                                No</label>
                            </div>
                          </div>
                        </div>




                        <div class="form-group setting_form_grp">
                          <div class="row">
                            <label class="control-label col-sm-5" for="name">Max Times To be used:</label>
                            <div class="col-sm-6">
                              <div>
                                <input type="text" class="form-control" v-model="installments" @blur="v$.installments.$touch" placeholder="Enter Max Times To be used">

                              </div>
                              <div class="col-sm-6 input_error" v-if="v$.installments.$error">Installments field is required.</div>

                            </div>

                          </div>
                        </div> <!--installments -->
                        <div class="form-group setting_form_grp">
                          <div class="row">
                            <label class="control-label col-sm-5" for="dm_consumer_secret"> Select custom expiry date:</label>
                            <div class="col-sm-6">
                              <label class="radio-inline"><input type="radio" v-model="customExpiryDate" :value="1">&nbsp; Yes </label> &nbsp;&nbsp;
                              <label class="radio-inline"><input type="radio" v-model="customExpiryDate" :value="0"> &nbsp; No</label>
                            </div>
                          </div>
                          <transition name="fade">
                            <div v-if="customExpiryDate">
                              <div class="form-group setting_form_grp">
                                <div class="row">
                                  <label class="control-label col-sm-5" for="name"> Expiry Date:</label>
                                  <div class="col-sm-6">
                                    <Datepicker v-model="expiryDate"></Datepicker>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </transition>
                        </div> <!--expiry date -->
                        <transition name="fade">
                          <div v-if="!customExpiryDate">
                            <div class="form-group setting_form_grp">
                              <div class="row">
                                <label class="control-label col-sm-5" for="name">Select validity period:</label>
                                <div class="col-sm-6">
                                  <select class="form-control" id="" v-model="validilityPeriod">
                                    <option value="">Select a Period</option>
                                    <option value="1 week">1 week</option>
                                    <option value="1 month">1 month</option>
                                    <option value="3 months">3 months</option>
                                    <option value="6 months">6 months</option>
                                    <option value="12 months">12 months</option>
                                  </select>
                                </div>
                              </div>
                            </div>
                          </div>
                        </transition><!--Validity date -->

                        

                        

                      </div>
                    </div><!--left Col-->
                    <div class="col-md-6 pad-l-0">
                      <div class="pl-4 pr-5 bd-b">
                        <h2 class="section-title">Offer</h2>
                        <div class="form-group setting_form_grp">
                          <div class="row">
                            <label class="control-label col-sm-4" for="dm_consumer_secret"> Enable Offer:</label>
                            <div class="col-sm-6">
                              <label class="radio-inline"><input type="radio" v-model="enableOffer" :value="1">&nbsp; Yes </label> &nbsp;&nbsp;
                              <label class="radio-inline"><input type="radio" v-model="enableOffer" :value="0"> &nbsp; No</label>
                            </div>
                          </div>
                        </div>

                        <div class="form-group setting_form_grp" v-if="enableOffer">
                          <div class="row">
                            <label class="control-label col-sm-4" for="name"> Start Date:</label>
                            <div class="col-sm-6">
                              <Datepicker v-model="startDate" autoApply></Datepicker>
                            </div>
                          </div>
                        </div>

                        <div class="form-group setting_form_grp" v-if="enableOffer">
                          <div class="row">
                            <label class="control-label col-sm-4" for="name"> End Date:</label>
                            <div class="col-sm-6">
                              <Datepicker v-model="endDate" autoApply></Datepicker>
                            </div>
                          </div>
                        </div>
                      </div> <!--Offer-->
                        
                      <div class="setting_content pl-4 pr-5 pt-4 bd-b" v-if="enableOffer">
                        

                        <div id="offer_amount_range" class="row" v-for="(divCount, divIndex) in divCounts" :key="divCount.key">
                          
                          <div class="col-1 order-last">
                            <span v-if="divCount.key !==1" @click="removeCountDivs(divCount.key)" class="div_remove_btn remove_icon glyphicon glyphicon-remove"></span>

                          </div>
                          <div class="col-md-11">
                            <div class="row">
                              <div class="col-md-4">
                                <div class="form-group">
                                  <label>Minimum Amount</label>
                                  <input type="number" class="form-control" v-model="minimumAmount[divIndex]" placeholder="Enter Minimum Amount">
                                </div>
                              </div>
                              <div class="col-md-4">
                                <div class="form-group">
                                  <label>Maximum Amount</label>
                                  <input type="number" class="form-control" v-model="maximumAmount[divIndex]" placeholder="Enter Maximum Amount">

                                </div>
                              </div>
                              <div class="col-md-4">
                                <div class="form-group">
                                  <label>Offer Amount</label>
                                  <input type="number" class="form-control" v-model="offerAmount[divIndex]" placeholder="Enter Offer Amount">
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div id="add_btn">
                          <label @click="createCountDivs" style="cursor:pointer;"><span class="glyphicon glyphicon-plus"></span> Add Price Range</label>
                        </div>

                      </div> <!--Price range-->

                      <div class="setting_content pl-4 pr-5 pt-4">
                        <h2 class="section-title">Theme Settings</h2>
                        <div class="form-group setting_form_grp">
                          <div class="row">
                            <label class="control-label col-sm-5" for="name">Use Default Theme:</label>
                            <div class="col-sm-6">
                              <label class="radio-inline"><input type="radio" v-model="theme" :value="1">&nbsp;
                                Yes </label> &nbsp;&nbsp;
                              <label class="radio-inline"><input type="radio" v-model="theme" :value="0">
                                &nbsp; No</label>
                            </div>
                          </div>
                        </div>
                        <div class="form-group setting_form_grp">
                          
                          <label>Coupon Template</label>
                          <textarea class="form-control" rows="20" cols="600" v-model="couponTempalate"></textarea>
                        <!-- <div class="">
                        <editor
                          v-model="couponTempalate"
                          :init ="{
                            selector: 'textarea',
                            format: 'raw',
                            height: 500,
                            editor_encoding : 'raw',
                            branding: false
                          }"
                        />
                      </div> -->
                          
                        
                        </div>
                        <div class="form-group setting_form_grp">
                          <div v-if="preview" v-html="cleantHtml(couponTempalate)"></div>
                          <button type="button" @click="previewContent" class="btn btn-light"><i class="fas fa-eye"></i> Preview Coupon template</button>
                        </div>
                    
                      </div>

                    </div><!--Right Col-->

                  </div>
                    
                  <div class="row bt-b">
                    <div class="col-md-12">
                      <div class="form-group ml-2r mt-3">
                        <button type="submit" class="btn btn-orange mr-3"><span v-if="spinner" v-html="searchspinner"></span> <span v-else>Update Changes</span></button>
                      </div>
                    </div>
                  </div>
                <!-- end row -->
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- loader -->
      <div id="main-loader"></div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import { useToast } from "vue-toastification";
import ReusableFunctionMixins from '@/mixins/ReusableFunctionMixins';
import ReusableDataMixins from '@/mixins/ReusableDataMixins';

import "tinymce/tinymce";
import "tinymce/themes/silver";
import "tinymce/icons/default";
import "tinymce/skins/ui/oxide/skin.css";
import Editor from '@tinymce/tinymce-vue';
import DOMPurify from 'isomorphic-dompurify';

const toast = useToast();
export default {

    components: {
        'editor': Editor
    },
    setup() {
        return { v$: useVuelidate() }
    },
    mounted: async function () {
        this.getVoucherId();
        const url = this.dashBaseUrl
        this.url = url.replace("/api/v1", "/")
    },
    data() {
        return {
            name: '',
            amount: 0,
            images: null,
            divCounts: [],
            startDate: moment(),
            expiryDate: moment(),
            endDate: moment().add(1, 'days'),
            offerAmount: [],
            maximumAmount: [],
            minimumAmount: [],
            customAmount: 0,
            theme: 1,
            enableOffer: 0,
            searchspinner: "<i class='fa fa-spinner fa-spin '></i> Updating...",
            imageUrl: '',
            validilityPeriod: '',
            installments: '',
            customExpiryDate: 0,
            description: '',
            usdAmount: '',
            status: 1,
            errors:false,
            errorMsg:[],
            spinner: false,
            voucherId: this.$route.params.id,
            externalVoucherId: '',
            couponTempalate:'',
            allowMultipleAmount: 0,
            vAmount: '',
            disableAddBtn:true,
            showOnWeb:1,
            amountArr:[],
            usePercentageBased:0,
            allowDiscountCode:0,
            percentValue: 0,
            discountCode: '',
            discountPercentage: 0,
            discountPercentageArray:[],
        };
    },
    validations() {
        return {
            name: { required },
            amount: { required },
            installments: { required },
        }
    },
    watch: {
      vAmount(){
        if(this.vAmount > 0){
          this.disableAddBtn = false;
        }
      },
      discountCode(){
        if(this.discountCode.length > 0){
          this.disableAddBtn = false;
        }
      },
      percentValue(){
        if(this.percentValue == 100){
          this.showOnWeb = 0;
          this.installments = 1;
        }
      }
    },
    methods: {
      cleantHtml(text){
          const clean = DOMPurify.sanitize(text);
          return clean
        },
        addAmount(){
        this.amountArr
        if(this.amountArr.filter((x) => x==this.vAmount).length ==0){
          this.amountArr.push(this.vAmount)
          this.vAmount = '';
        }
      },
      addDiscount(){
      if (this.discountPercentageArray.filter((x) => x.discount_code==this.discountCode).length ==0){
          this.discountPercentageArray.push(
            {'discount_code':this.discountCode,
            'percentage_value':this.discountPercentage}
          )
      }

    },
    removediscountPercentage(discountCode){
      this.discountPercentageArray = this.discountPercentageArray.filter((x) => x.discount_code!=discountCode)
    },
      removeAmount(amount){
        this.amountArr = this.amountArr.filter((x) => x !=amount)
      },
        async uploadGiftcardImage(event, height, width) {
            // initiating loader
            const imageLoader = document.getElementById('foodie-loading-cover');
            imageLoader.style.opacity = .4;
            const loader = document.getElementById('foodie-loader');
            loader.style.display = 'block';
            const files = event.target.files || event.dataTransfer.files;

            if (!files.length) {
                toast.error("file too large");
                return;
            }
            // const type = files.type;
            // const valid =type.indexOf("image") !== -1;
            // if(!valid){
            //     toast.error("File Type Is Not Supported. Upload an image instead");
            //     return;
            // }
            const file = files[0];


            const allowedExtensions =
                          /(\.jpg|\.jpeg|\.png|\.gif|\.WEBP)$/i;
                  // end typ
            const fileName = file.name
            if (!allowedExtensions.exec(fileName)) {
                toast.error("cannot support the file type please use jpg,jpeg,png,gif or WEBP");
                loader.style.display = 'none';
                imageLoader.style.opacity = 1;
                return false;
            }

            const reader = new FileReader();
            // Wait for the data url to be loaded from the file
            const dataURL = await new Promise(resolve => {
                reader.onload = (e) => resolve(e.target.result);
                reader.readAsDataURL(file);
            });
            // wait for the image to be loaded
            const img = new Image();
            await new Promise(resolve => {
                img.onload = resolve;
                img.src = dataURL;
            });
            // Resize the image with a canvas
            const canvas = document.createElement("canvas");
            const ctx = canvas.getContext("2d");
            // This is an addition to keep the aspect ratio of the image so it won't get distorted
            const [maxWidth, maxHeight] = [height, width];
            const [imgWidth, imgHeight] = [
                img.naturalWidth,
                img.naturalHeight
            ];
            const ratio = Math.min(maxWidth / imgWidth, maxHeight / imgHeight);
            canvas.width = imgWidth * ratio;
            canvas.height = imgHeight * ratio;
            ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
            const images = canvas.toDataURL('image/jpeg', 0.9);

            const binary = atob(images.split(',')[1]);
            const array = [];
            for (let i = 0; i < binary.length; i++) {
                array.push(binary.charCodeAt(i));
            }
            const blobData = new Blob([new Uint8Array(array)], { type: 'image/jpeg' });

            const formData = new FormData();

            formData.append('image', blobData);
            const config = {
                headers: {
                    'content-type': 'multipart/form-data',
                    'Authorization': `Bearer ${this.token}`
                }
            };
            const url = this.dashBaseUrl + "/upload-photo";
            this.axios.post(url, formData, config).then((response) => {
                    if (response.status === 200) {
                        // this.url = images;
                        this.imageUrl = response.data.image_url;
                        this.images = images;
                        loader.style.display = 'none';
                        imageLoader.style.opacity = 1;
                    }
                })
                .catch((error) => {
                    if (error.response) {
                        toast.error('Code: ' + error.response.status + ', message: ' + error.response.data.message);
                        loader.style.display = 'none';
                        imageLoader.style.opacity = 1;
                    }
                })
        },
        createCountDivs() {
            this.divCounts.push({
                'key': Math.random()
            });
            return this.divCounts;
        },

        removeCountDivs(key) {
            this.divCounts = this.divCounts.filter(
                (x) => x.key !== key
            );
            return this.divCounts;
            // 
        },

        async updateVoucher() {
            this.spinner = true;
            const isFormCorrect = await this.v$.$validate()
            if (!isFormCorrect) {
                this.spinner = false;
                return
            }
            const offerData = [];
            for (let x = 0; x < this.divCounts.length; x++) {
                offerData.push({
                    "minimum_amount": this.minimumAmount[x],
                    "maximum_amount": this.maximumAmount[x],
                    "offer_amount": this.offerAmount[x]
                });
            }

            const postData = {
                "merchant_id": parseInt(this.merchantID),
                "name": this.name,
                "amount": this.amount,
                "custom_amount": this.customAmount,
                "image_url": this.imageUrl,
                "usd_amount": this.usdAmount,
                "installments": this.installments,
                "custom_expiry": this.customExpiryDate,
                "validilityPeriod": !this.customExpiryDate ? this.validilityPeriod : '',
                "expiry_date": this.customExpiryDate ? this.expiryDate : '',
                "theme": this.theme,
                "is_offer": this.enableOffer,
                "description": this.description,
                "start_date": this.startDate,
                "end_date": this.endDate,
                'status': this.status,
                "offer_data": offerData,
                "v_amounts": this.amountArr,
                "allow_multiple_amount":this.allowMultipleAmount,
                'coupon_template': this.cleantHtml(this.couponTempalate),
                "show_on_web": this.showOnWeb,
                "percentage_based": this.usePercentageBased,
                "discount_based": this.allowDiscountCode,
                "percentage_value": this.percentValue,
                "percenta_discounts": this.discountPercentageArray
            }
            const url = this.dashBaseUrl + '/update-voucher/' + this.voucherId;
            const config = {
                headers: { 'Authorization': `Bearer ${this.token}`, 'Accept': 'application/json' }
            };
            this.axios.put(url, postData, config).then((response) => {
                    if (response.status === 200) {
                        toast.success("voucher updated successfully");
                        this.$router.push({
                            name: 'giftcardstype'
                        })
                    } else {
                        toast.error(response.data.message);
                    }
                })
                .catch((err) => {
                  if(err.response.status === 400){
                    this.errors = true;
                      this.errorMsg = err.response.data.errors
                  }
                }).finally(()=> this.spinner = false);
            return;
        },
        async getVoucherId() {
            const MainLoaderCover = document.getElementById('main-loading-cover');
            const Mainloader = document.getElementById('main-loader')
            MainLoaderCover.style.opacity = .4;
            Mainloader.style.display = 'block';
            const config = {
                headers: { 'Authorization': `Bearer ${this.token}`, 'Accept': 'application/json' }
            };
            const url = this.dashBaseUrl + "/voucher/" + this.voucherId + "/"+this.merchantID;
            this.axios.get(url, config).then((response) => {
                MainLoaderCover.style.opacity = 1;
                Mainloader.style.display = 'none';
                if (response.status === 200) {
                    this.description = response.data.data.voucher.voucher_desc;
                    this.externalVoucherId = response.data.data.voucher.external_voucher_id;
                    this.name = response.data.data.voucher.voucher_name;
                    this.imageUrl = response.data.data.voucher.voucher_image;
                    this.images = this.imageUrl;
                    this.amount = response.data.data.voucher.voucher_amount;
                    this.installments = response.data.data.voucher.voucher_installments;
                    this.usdAmount = response.data.data.voucher.voucher_usd_amount;
                    this.customExpiryDate = response.data.data.voucher.voucher_custom_expiry;
                    this.validilityPeriod = response.data.data.voucher.voucher_expiry_period;
                    if (response.data.data.voucher.voucher_expiry_date) {
                        this.expiryDate = response.data.data.voucher.voucher_expiry_date;
                    }
                    this.theme = response.data.data.voucher.use_default_theme;
                    this.showOnWeb = response.data.data.voucher.show_on_web;
                    this.couponTempalate = response.data.data.voucher.coupon_template;
                    this.customAmount = response.data.data.voucher.allow_custom_amount;
                    this.status = response.data.data.voucher.status;
                    this.allowMultipleAmount = response.data.data.voucher.allow_multiple_amount;


                    this.usePercentageBased = response.data.data.voucher.percentage_based;
                    this.allowDiscountCode = response.data.data.voucher.discount_based;
                    this.percentValue = response.data.data.voucher.percentage_value;
          


                    const voucherDiscounts = response.data.data.voucher.voucher_discounts ? response.data.data.voucher.voucher_discounts : [];
                    for(let x=0; x < voucherDiscounts.length ; x++){
                      if (this.discountPercentageArray.filter((x) => x.discount_code==voucherDiscounts[x].discount_code).length ==0){
                          this.discountPercentageArray.push(
                            {
                              'discount_code':voucherDiscounts[x].discount_code,
                              'percentage_value':voucherDiscounts[x].discount_percentage
                            }
                          )
                      }

                    }
                    const Amounts = response.data.data.voucher.voucher_amounts;

                    for(let x=0; x < Amounts.length ; x++){
                      this.amountArr.push(Amounts[x].amount)
                     
                    }

                    this.enableOffer = response.data.data.voucher.is_offer
                    if (response.data.data.offers.length != 0 && this.enableOffer) {
                        for (let x = 0; x < response.data.data.offers.length; x++) {
                            this.startDate = response.data.data.offers[x].start_date;
                            this.endDate = response.data.data.offers[x].end_date;
                            this.minimumAmount[x] = response.data.data.offers[x].minimum_amount;
                            this.maximumAmount[x] = response.data.data.offers[x].maximum_amount;
                            this.offerAmount[x] = response.data.data.offers[x].offer_amount;
                            this.divCounts.push({
                                'key': Math.random()
                            });
                        }
                    }

                    // this.gitCardsType = response.data.data;
                }
            }).catch((err) => {
                console.log('error', err);
                MainLoaderCover.style.opacity = 1;
                Mainloader.style.display = 'none';
            });

        }

    },
    mixins: [ReusableFunctionMixins, ReusableDataMixins]

};
</script>
